import React, { useContext } from "react";
import ModalTour from "./ModalTour";
import { GlobalContext } from "../App";
import { GreyCallout } from "../Layout";
import { Link } from "react-router-dom";
import qs from "query-string";
import confetti from "canvas-confetti";
import * as _ from "lodash";
import { logger } from "../lib/Logger";
import { useEffect } from "react";
import { sendGtmEvent } from "../lib/utils";

let confettiPopped = false;
export const ThanksForUpgrading = (props = {}) => {
  const { loggedInUser } = useContext(GlobalContext);
  const { upgradedPlan } = qs.parse(window.location.search);

  // analytics
  useEffect(() => {
    if (upgradedPlan === "v3_base") {
      logger.log(`billing: base plan upgrade success!`);
      sendGtmEvent("upgrade_thanks_base_plan", {
        category: "upgrade",
        action: "upgrade_thanks_base_plan"
      });
    } else {
      logger.log(`billing: addon upgrade success ${upgradedPlan}`);
      sendGtmEvent("upgrade_thanks_addon", {
        category: "upgrade",
        action: "upgrade_thanks_addon",
        label: upgradedPlan
      });
    }
  }, []);

  // confetti
  if (!confettiPopped) {
    confetti();
    confettiPopped = true;
  }

  if (!loggedInUser) return null;

  return (
    <ModalTour
      containerStyle={props.containerStyle}
      currentStepId={props.currentStepId || null}
      onClose={props.onClose}
      closeTour={props.closeTour}
      visible={!_.isUndefined(props.visible) ? props.visible : true}
      tourSteps={tourControls => {
        return [
          {
            content: (
              <div>
                <h1>Thank you for upgrading</h1>
                <p>Your Followup Assistant just leveled up ✨ </p>
                <br />

                <p>Now let's get some things done!</p>
                <img
                  src="https://files-dl04onacn.vercel.app/inbox-zero.png"
                  style={{ width: "100%", margin: "50px 0px" }}
                />
                <br />
                <p>
                  <strong>Tip:</strong>{" "}
                  <Link
                    onClick={() => {
                      props.closeTour();
                    }}
                    to="/settings"
                  >
                    Add your other email addresses
                  </Link>{" "}
                  so your upgrade works everywhere.
                </p>
                <br />
                <br />
                <GreyCallout style={{ padding: 10 }}>
                  <strong>Do you have a team? 🙌</strong> Add them from{" "}
                  <Link onClick={() => props.closeTour()} to="/team">
                    team settings
                  </Link>
                  .
                </GreyCallout>
              </div>
            ),
            buttonText: null
          }
        ];
      }}
    />
  );
};
