import React, { Component } from "react";
import { Link, Switch, Route } from "react-router-dom";
import { isValidEmail, mailbotsAdminBrowser } from "./lib/utils";
import { message as antMessage } from "antd";
import cookie from "js-cookie";
import nprogress from "nprogress";
import futLogo from "./images/futLogo.svg";
import { withRouter } from "react-router-dom";
import { FloatingCard } from "./Card";
import queryString from "query-string";
import Alert from "./Alert";
import { logger } from "./lib/Logger";
import * as _ from "lodash";
import GoogleLoginButton from "./GoogleLoginButton";
import MicrosoftLoginButton from "./MicrosoftLoginButton";
import Loading from "./Loading";

const Forgot = ({ handleForgotPassword, email, handleOnChange, loading }) => (
  <FloatingCard className="login-container">
    <form method="post" onSubmit={handleForgotPassword}>
      <fieldset>
        <div className="form-group">
          <p>We'll send you a magic login link 🪄</p>
          <input
            autoFocus={true}
            type="text"
            className="form-control"
            id="inputEmail"
            placeholder="Enter your email"
            value={email}
            onChange={handleOnChange}
            name="email"
          />
          <br />
          <button
            type="submit"
            className="btn btn-primary btn-mailbots btn-block"
            style={{ opacity: loading ? 0.5 : 1 }}
            href="/auth/login"
          >
            {loading ? "Sending..." : "Send Login Link"}
          </button>
          <br />
          <Link style={{ textAlign: "center", display: "block" }} to={"/login"}>
            Login with password instead
          </Link>
        </div>
      </fieldset>
    </form>
  </FloatingCard>
);

const LoginForm = ({
  reloadUserSubmit,
  handleGoogleLogin,
  handleMsLogin,
  handleOnChange,
  email,
  password,
  loading
}) => (
  <FloatingCard className="login-container">
    {" "}
    <p>
      Sign in below, or <Link to={"/auth/email-only"}>with email only.</Link>
    </p>
    <form method="post" onSubmit={reloadUserSubmit}>
      <fieldset>
        <div className="form-group">
          <input
            autoFocus={true}
            type="text"
            className="form-control mailbots-data"
            id="email"
            placeholder="Email"
            key="email"
            name="email"
            onChange={handleOnChange}
            value={email || ""}
            style={{ marginBottom: 10 }}
          />
          <input
            type="password"
            className="form-control mailbots-data"
            id="password"
            placeholder="Password"
            name="password"
            key="password"
            onChange={handleOnChange}
            value={password || ""}
            style={{ marginBottom: 10 }}
          />
          {/* <span className="help-block" style={{ textAlign: "right" }}>
            <Link style={{ color: "#ccc" }} to={"/auth/email-only"}>
              Forgot Password?
            </Link>
          </span> */}
          <button
            type="submit"
            className="btn btn-primary btn-mailbots btn-block"
            style={{ opacity: loading ? 0.5 : 1 }}
          >
            {loading ? `Logging In...` : `Login`}
          </button>
        </div>
      </fieldset>
    </form>
    <div style={{ position: "relative", margin: 25 }}>
      <span
        className="text-muted"
        style={{
          position: "absolute",
          backgroundColor: "#fff",
          padding: "0 10px",
          top: -12,
          left: "45%",
          fontStyle: "italic"
        }}
      >
        or
      </span>
      <hr style={{ marginTop: 10, marginBottom: 10 }} />
    </div>
    <div>
      <GoogleLoginButton
        disabled={loading}
        onLogin={code => handleGoogleLogin(code)}
      ></GoogleLoginButton>
    </div>
    <div style={{ marginTop: "5px" }}>
      <MicrosoftLoginButton
        disabled={loading}
        onLogin={accessToken => handleMsLogin(accessToken)}
      ></MicrosoftLoginButton>
    </div>
  </FloatingCard>
);

const Signup = ({
  handleSignupSubmit,
  handleOnChange,
  email,
  loading,
  handleGoogleLogin,
  handleMsLogin
}) => (
  <FloatingCard className="login-container">
    {" "}
    <p>Enter your email to get started.</p>
    <form method="post" onSubmit={handleSignupSubmit}>
      <fieldset>
        <div className="form-group">
          <input
            autoFocus={true}
            type="text"
            className="form-control mailbots-data"
            id="email"
            placeholder="your@email.com"
            key="email"
            name="email"
            onChange={handleOnChange}
            value={email || ""}
            style={{ marginBottom: 12 }}
          />
          <button
            type="submit"
            className="btn btn-primary btn-mailbots btn-block"
            style={{ opacity: loading ? 0.5 : 1 }}
          >
            {!loading ? `Sign Up` : `Signing Up 🚀 `}
          </button>
        </div>
      </fieldset>
    </form>
    <div style={{ position: "relative", margin: 25 }}>
      <span
        className="text-muted"
        style={{
          position: "absolute",
          backgroundColor: "#fff",
          padding: "0 10px",
          top: -12,
          left: "45%",
          fontStyle: "italic"
        }}
      >
        or
      </span>
      <hr style={{ marginTop: 10, marginBottom: 10 }} />
    </div>
    <div>
      <GoogleLoginButton
        disabled={loading}
        onLogin={code => handleGoogleLogin(code)}
      ></GoogleLoginButton>
    </div>
    <div style={{ marginTop: "5px" }}>
      <MicrosoftLoginButton
        disabled={loading}
        onLogin={accessToken => handleMsLogin(accessToken)}
      ></MicrosoftLoginButton>
    </div>
  </FloatingCard>
);
class Login extends Component {
  constructor(props) {
    super(props);
    const qs = queryString.parse(window.location.search);
    this.qsMessage = qs.message ? decodeURIComponent(qs.message) : null;
    this.sso = qs.sso;
    this.code = qs.code;
    this.signupHash = qs.signupHash;
    this.state = {
      email: qs.email || "",
      password: ""
    };
  }

  async componentDidMount() {
    try {
      logger.log("login screen shown");
      // www site sends new users here with ?signupHash=1&email=fdsa@fdsa.com
      // @todo - this is a hack, we should be able to do this in the backend
      // @todo implement signupHash https://github.com/mailbots/fut-admin-ui/issues/192
      if (this.signupHash && this.state.email) {
        logger.log("email signup started");
        this.handleSignupSubmit();
      } else if (this.sso === "google" && this.code) {
        logger.log("user login started with google");
        await this.handleGoogleLogin(this.code);
      } else if (this.sso === "ms" && this.code) {
        logger.log("user login started with ms");
        await this.handleMsLogin(this.code);
      }
    } catch (error) {
      logger.log("error logging in from url", {
        level: "warn",
        debug: { error }
      });
      console.error(error);
      this.props.history.push("/login");
    }
  }

  /**
   * Workaround to capture fields the browser is autocompleting,
   * and thus not firing onChange events.
   */
  captureWholeForm = function () {
    return new Promise((resolve, reject) => {
      let fields = document.getElementsByClassName("mailbots-data");
      let form = {};
      for (let prop in fields) {
        let k = fields[prop].name;
        let v = fields[prop].value;
        if (k && v) {
          form[k] = v;
        }
        let newForm = Object.assign({}, this.state, form);
        this.setState({ ...newForm }, resolve);
      }
    });
  };

  handleOnChange = event => {
    let form = {};
    if (event.target.type === "checkbox") {
      form[event.target.name] = event.target.checked;
    } else {
      form[event.target.name] = event.target.value;
    }
    this.setState({
      ...form
    });
  };

  handleRedirect = loggedInUser => {
    try {
      if (window.location.search.includes("redirect")) {
        const qs = queryString.parse(window.location.search);
        cookie.remove("mailbotsRedirect");
        this.props.history.push(decodeURIComponent(qs.redirect));
      } else if (cookie.get("mailbotsRedirect")) {
        this.props.history.push(cookie.get("mailbotsRedirect"));
        cookie.remove("mailbotsRedirect");
      } else {
        if (!loggedInUser.accepted_terms) {
          this.props.history.push("/");
        } else {
          this.props.history.push("/");
        }
      }
    } catch (error) {
      let errorMsg =
        error.message ||
        `Sorry, something went wrong! Feel free to email help@humans.fut.io if you need help.`;
      antMessage.error(errorMsg);
    }
  };

  reloadUserSubmit = async e => {
    e.preventDefault();
    await this.captureWholeForm();
    try {
      nprogress.start();
      logger.log("user is loggin in");
      this.setState({ loading: true });
      let res = await mailbotsAdminBrowser.login(this.state);
      const accessToken = res.user.token.access_token;
      await this.props.reloadUser({ accessToken });
      nprogress.done();
      this.setState({ loading: false });

      // redirect to where they were going...
      if (cookie.get("mailbotsRedirect")) {
        const redirectTo = cookie.get("mailbotsRedirect");
        logger.log("redirect on login from cookie", {
          data: { redirect_to_str: redirectTo }
        });
        // prevent loop
        if (redirectTo === "/login") {
          this.props.history.push("/");
        } else {
          this.props.history.push(redirectTo);
        }
        cookie.remove("mailbotsRedirect");
      } else {
        this.props.history.push("/");
      }
      logger.log("user successfuly logged in");
    } catch (e) {
      if (e.message === "Email or password is invalid") {
        this.props.history.push("/auth/email-only");
        e.message =
          e.message + " How about we send you a magic login link instead?";
      }
      nprogress.done();
      this.setState({ loading: false });
      console.log(e);
      logger.log("failed to log in user", {
        level: "warn",
        data: { email: this.state.email }
      });
      antMessage.error(e.message);
    }
  };

  errorTypeUserAlreadyExists(e) {
    return e.message.includes("A user with that email address already exists");
  }

  handleSignupSubmit = async event => {
    if (event) event.preventDefault();
    try {
      nprogress.start();
      if (!isValidEmail(this.state.email))
        throw Error(
          "Please enter a valid email address. For example email@example.com"
        );

      // parse affiliate string from URL ?a=fdsa9fdsa (referral hash from another user)
      const { a: referral_hash } = queryString.parse(window.location.search);

      const regForm = {
        email: this.state.email,
        referral_hash
      };
      const qs = queryString.parse(window.location.search);
      if (qs.tid && qs.h) {
        regForm.team_ownerid = qs.tid;
        regForm.team_signup_hash = qs.h;
      }
      const regRes = await mailbotsAdminBrowser.register(regForm);
      const accessToken = _.get(regRes, "user.token.access_token");
      await this.props.reloadUser({ accessToken });
      this.props.history.push("/skills");
      nprogress.done();
    } catch (e) {
      nprogress.done();
      if (this.errorTypeUserAlreadyExists(e)) {
        antMessage.error(
          "It looks like a user already exists with that email. Should we email you a link to login?"
        );
        this.props.history.push("/auth/email-only");
      } else {
        console.log(e);
        let error =
          e.message ||
          `Sorry, something went wrong! Feel free to email help@humans.fut.io if you need help.`;
        antMessage.error(error);
      }
    }
  };

  handleForgotPassword = async e => {
    e.preventDefault();
    nprogress.start();
    try {
      const email = this.state.email && this.state.email.trim();
      if (!isValidEmail(email))
        throw Error(
          "Please enter a valid email address. For example, email@example.com"
        );
      logger.log("send login link clicked");
      this.setState({ loading: true });
      let res = await mailbotsAdminBrowser.forgotPassword({
        email
      });
      nprogress.done();
      this.setState({ loading: false });
      if (res instanceof Error) throw res;
      antMessage.success(
        "Done! An email will be in your inbox shortly. Make sure to check your spam folder."
      );
      logger.log("send login link sent");
    } catch (e) {
      if (e.message.startsWith("FollowUpThen account not found.")) {
        e.message =
          e.message +
          " Is that the right address? You can also create a new account.";
      }
      nprogress.done();
      this.setState({ loading: false });
      console.log(e);
      antMessage.error(e.message);
    }
  };

  handleGoogleLogin = async code => {
    try {
      nprogress.start();
      this.setState({ loading: true });
      const { a: referral_hash } = queryString.parse(window.location.search);
      const res = await mailbotsAdminBrowser.loginWithGoogle({
        code,
        referral_hash
      });
      const accessToken = res.user.token.access_token;
      const loggedInUser = await this.props.reloadUser({ accessToken });
      this.handleRedirect(loggedInUser);
      logger.log("user successfully logged in via Google");
    } catch (e) {
      logger.log("error logging in with google");
      console.log(e);
      let error = `Sorry, something went wrong. (${e.message}) Feel free to email help@humans.fut.io if you need help.`;
      antMessage.error(error);
      this.props.history.push("/login");
    }

    this.setState({ loading: false });
    nprogress.done();
  };

  handleMsLogin = async msAccessToken => {
    try {
      nprogress.start();
      this.setState({ loading: true });
      const { a: referral_hash } = queryString.parse(window.location.search);
      const res = await mailbotsAdminBrowser.loginWithMicrosoft({
        access_token: msAccessToken,
        referral_hash
      });
      const accessToken = res.user.token.access_token;
      const loggedInUser = await this.props.reloadUser({ accessToken });
      this.handleRedirect(loggedInUser);
      logger.log("user successfully logged in via MS");
    } catch (e) {
      console.log(e);
      logger.log("error logging in with microsoft");
      let error = `Sorry, something went wrong. (${e.message}) Feel free to email help@humans.fut.io if you need help.`;
      antMessage.error(error);
      this.props.history.push("/login");
    }

    this.setState({ loading: false });
    nprogress.done();
  };

  Forgot = () => (
    <FloatingCard className="login-container">
      <form method="post" onSubmit={this.handleForgotPassword}>
        <fieldset>
          <div className="form-group">
            <p>
              Get a magic login link, or{" "}
              <Link to={"/login"}>login with password</Link>.
            </p>
            <input
              autoFocus={true}
              type="text"
              className="form-control"
              id="inputEmail"
              placeholder="Enter your email"
              value={this.state.email}
              onChange={this.handleOnChange}
              name="email"
            />
            <br />
            <button
              type="submit"
              className="btn btn-primary btn-mailbots btn-block"
              style={{ opacity: this.state.loading ? 0.5 : 1 }}
              href="/auth/login"
            >
              {this.state.loading ? "Sending magic..." : "Send Login Link"}
            </button>
            <br />
          </div>
        </fieldset>
      </form>
    </FloatingCard>
  );

  render() {
    if ((this.state.email && this.signupHash) || (this.sso && this.code)) {
      return (
        <div
          style={{
            width: "100",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div style={{ width: 450, textAlign: "center" }}>
            <img src={futLogo} alt="logo" style={{ width: 200 }} />
            <Loading loadingText="...getting things ready." />
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        {this.qsMessage ? (
          <Alert
            level="grey"
            dissmissable={false}
            style={{
              maxWidth: 500,
              margin: 50,
              marginRight: "auto",
              marginLeft: "auto"
            }}
          >
            <p style={{ marginBottom: 0 }}>{this.qsMessage}</p>
          </Alert>
        ) : null}

        <a href="https://www.followupthen.com/">
          <img
            src={futLogo}
            alt="FollowUpThen Logo"
            className="login-container-logo"
          />
        </a>
        <Switch>
          <Route
            path="/auth/signup"
            exact
            render={() => (
              <Signup
                handleOnChange={this.handleOnChange}
                handleSignupSubmit={this.handleSignupSubmit}
                email={this.state.email}
                loading={this.state.loading}
                handleGoogleLogin={this.handleGoogleLogin}
                handleMsLogin={this.handleMsLogin}
              />
            )}
          />

          <Route
            exact
            path="/auth/email-only"
            render={() => (
              <Forgot
                handleForgotPassword={this.handleForgotPassword}
                email={this.state.email}
                handleOnChange={this.handleOnChange}
                loading={this.state.loading}
              />
            )}
          />
          <Route
            path={["/login", "/auth"]}
            render={() => (
              <LoginForm
                handleOnChange={this.handleOnChange}
                handleGoogleLogin={this.handleGoogleLogin}
                handleMsLogin={this.handleMsLogin}
                reloadUserSubmit={this.reloadUserSubmit}
                email={this.state.email}
                password={this.state.password}
                loading={this.state.loading}
              />
            )}
          />
        </Switch>
        {window.location.pathname.startsWith("/auth/signup") ? (
          <p>
            Already have an account?{" "}
            <Link className="muted" to={"/login"}>
              Login
            </Link>
            .
          </p>
        ) : (
          <p>
            First time here?{" "}
            <Link className="muted" to={"/auth/signup"}>
              Create an account
            </Link>
            .
          </p>
        )}
        <br />
      </div>
    );
  }
}

export default withRouter(Login);
